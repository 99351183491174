@font-face {
  font-family: "NT-Impact";
  src: url("/fonts/impact.ttf") format("truetype")
}

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background-color: white;
}

input:focus {
  border-width: 0;
  box-shadow: 0 0 4px rgba(0,0,0,0.9);
  outline: 1px none;
  padding: 3px 2px;
  font: 13px 'Arial';
}

input {
  font: 13px 'Arial';
}

.root {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

html {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

header {
  font-size: 52px;
  font-weight: bold;
  text-align: center;
}

section {
  border: lightgray;
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  padding-bottom: 80px;
}

p {
  padding: 0;
  line-height: 1.8em;
}

section li, section p {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  color: rgba(26,26,26,.75);
}

$margin-sm: 30px;
$margin-md: 60px;
$margin-lg: 90px;

$margin-xl: 15%;

section p, section li {
  text-align: left;
}

h1 {
  text-align: center;
}

.nt-xl h1 {
  margin-top: 45px;
  margin-bottom: 50px;
  font-size: 38px;
}

.nt-lg h1 {
  margin-top: 50px;
  font-size: 22px;
}

.nt-md h1 {
  margin-top: 40px;
  font-size: 22px;
}

.nt-sm h1 {
  margin-top: 30px;
  font-size: 18px;
}

.nt-mbl h1 {
  display: none;
}

section .nt-xl li, section .nt-xl p, section .nt-xl a, .nt-xl time {
  font-size: 15px;
}

section .nt-lg li, section .nt-lg p, section .nt-lg§ a, .nt-lg time {
  font-size: 14px;
}

section .nt-md li, section .nt-md p, section .nt-md a, .nt-md time {
  font-size: 13px;
}

section .nt-sm li, section .nt-sm p, section .nt-sm a, .nt-sm time {
  font-size: 12px;
}

section .nt-xl .content {
  margin-left: $margin-xl;
  margin-right: $margin-xl;
}

section .nt-lg .content {
  margin-left: $margin-lg;
  margin-right: $margin-lg;
}

section .nt-md .content {
  margin-left: $margin-md;
  margin-right: $margin-md;
}

section .nt-sm .content {
  margin-left: $margin-sm;
  margin-right: $margin-sm;
}

.vid-wrp {
  margin-left: auto;
  margin-right: auto;
}

.video {
  margin-left: auto;
  margin-right: auto;
  background: black;
}

.nt-sm .vid-wrp {
  margin-top: 50px;
  margin-bottom: 34px;
}

.nt-sm .video {
  padding-bottom: 6%;
  padding-top: 6%;
}

.nt-md .vid-wrp {
  margin-top: 50px;
  max-width: 640px;
  margin-bottom: 45px;
}

.nt-md .video {
  width: 93%;
  padding-bottom: 6%;
  padding-top: 6%;
}

.nt-lg .vid-wrp {
  margin-top: 60px;
  max-width: 860px;
  margin-bottom: 50px;
}

.nt-lg .video {
  width: 84%;
  padding-bottom: 4%;
  padding-top: 4%;
}

.nt-xl .vid-wrp {
  margin-top: 50px;
  max-width: 940px;
  margin-bottom: 50px;
}

.nt-xl .video {
  width: 79%;
  padding-bottom: 4%;
  padding-top: 4%;
}


.code {
  background: #f0f0f0;
  font-style: italic;
  padding: 1px 4px;
}

.important-highlight {
  font-weight: bold;
  text-decoration: underline;
}

h1, h2, h3 {
  font-family: adobe-garamond-pro, sans-serif;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
  color: rgba(26,26,26,.9);
}

h1 {
  font-weight: 400;
}

.shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

a:hover {
  color: #f1b200;
}

a {
  font: 400 15px "Open Sans", sans-serif;
  text-decoration: none;
  color: #1397D1;
}

a:hover {
  color: #f1b200;
  text-decoration: underline;
}

.sprite {
  background-image: url('https://res.cloudinary.com/nifty-thoughts/image/upload/v1653803381/sprites_bu5ydf.png');
  position: absolute;
  transform-origin: top left;
}

time {
  color: #777777;
}

blockquote {
  background-color: #f4f4f4;
}

.bqt-svg {
  display: block;
}


